import { formatLabel } from "@/lib/common/utils"
import createCache from "@emotion/cache"
import { CacheProvider } from "@emotion/react"
import { Slider } from "@mui/material"
import rtlPlugin from "stylis-plugin-rtl"

interface SlideFilerProps {
  brandMin: number
  brandMax: number
  handleChange: (event: Event, newValue: number | number[]) => void
  minMaxPrice: number[]
}

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [rtlPlugin],
})
const SliderFilter = ({
  brandMin,
  brandMax,
  minMaxPrice,
  handleChange,
}: SlideFilerProps): JSX.Element => {
  return (
    <CacheProvider value={cacheRtl}>
      <Slider
        value={minMaxPrice}
        step={1}
        max={brandMax}
        min={brandMin}
        getAriaLabel={(index) =>
          index === 0 ? "בחר טווח מחירים מחיר מינימלי" : "בחר טווח מחירים מחיר מקסימלי"
        }
        valueLabelFormat={formatLabel}
        onChange={handleChange}
        getAriaValueText={formatLabel}
        valueLabelDisplay="on"
        disableSwap
        sx={{
          ".MuiSlider-valueLabel": {
            borderRadius: "8px",
            backgroundColor: "white",
            boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.10)",
            color: "#231F20",
          },
        }}
      />
    </CacheProvider>
  )
}

export default SliderFilter
