type TimeLeftProps = {
  hours: number
  minutes: number
  seconds: number
}

export function calculateTimeLeft(dueDate: string): TimeLeftProps {
  const difference = new Date(dueDate).getTime() - new Date().getTime()

  if (difference <= 0) {
    return { hours: 0, minutes: 0, seconds: 0 }
  }

  const hours = Math.floor(difference / (1000 * 60 * 60))
  const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((difference % (1000 * 60)) / 1000)

  return { hours, minutes, seconds }
}

export function getCardTimeLeft(dueDate: string): string {
  const { hours, minutes, seconds } = calculateTimeLeft(dueDate)
  if (hours === 0 && minutes === 0 && seconds === 0) {
    return "00:00:00"
  }
  if (hours >= 48) {
    const days = Math.floor(hours / 24)
    return `${days} ימים לסיום ההצעה`
  }
  return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
}

export function getStockText(stock: number): string {
  let text = ""
  if (stock === 1) {
    text = "אחרון במלאי!"
  } else if (stock === 2) {
    text = "נשארו 2 אחרונים"
  } else if (stock === 3) {
    text = "נשארו 3 אחרונים"
  }
  return text
}

export const formatter = new Intl.NumberFormat("en-NZ", {
  style: "currency",
  currency: "ILS",
  minimumFractionDigits: 0,
})

export const calculate = (
  carPrice: number,
  zeroKm: boolean,
  advanced: number,
  interestBank: number,
  interestPrime: number,
  ballon: number,
  payments: number,
): {
  monthlyPayments: number
  advancedPayment: number
  totalLoanAmount: number
  ballonPayment: number
  balloonInterestPayment: number
} => {
  let interest, loanCap
  if (zeroKm) {
    if (carPrice < 465000) {
      interest = interestPrime + 0.6
      loanCap = carPrice * 0.85 // the loan can be up to 85% with no max
    } else {
      interest = interestPrime + 0.6
      loanCap = 400000 // the loan can is up to 400000 when the car price is above 465000
    }
  } else {
    interest = interestBank + interestPrime
    loanCap = Math.min(carPrice * 0.85, 300000) // the loan can be up to 85% of car price or max 300000
  }
  const monthlyInterest = interest / 100 / 12 // the monthly interest is the annual interest divided by 100 and by 12
  const actualBallon = ballon // the effective balloon is the min between the balloon and the max balloon

  const adjustedLoan = loanCap - advanced - actualBallon // subtracting the balloon from the loan amount

  const monthlyPayments = Math.max(
    adjustedLoan * (monthlyInterest / (1 - Math.pow(1 + monthlyInterest, -payments))),
    (carPrice - advanced - actualBallon) *
      (monthlyInterest / (1 - Math.pow(1 + monthlyInterest, -payments))),
  )

  const balloonInterestPayment = actualBallon * monthlyInterest

  return {
    monthlyPayments: monthlyPayments + balloonInterestPayment,
    advancedPayment: advanced,
    totalLoanAmount: loanCap,
    ballonPayment: actualBallon,
    balloonInterestPayment: balloonInterestPayment,
  }
}
