import { ImageModel } from "@/api/generated/BMWInterfaces"
import Image from "next/image"
import ReactPlayer from "react-player/file"

type Props = {
  image: ImageModel
  index: number
}

const isVideo = (url: string): boolean => {
  return url.includes(".mp4") || url.includes("youtube")
}

const HeroMedia = ({ image, index }: Props): JSX.Element => {
  const isMediaVideo = isVideo(image.imageUrl)
  return isMediaVideo ? (
    <ReactPlayer
      controls={false}
      muted
      loop
      url={image.imageUrl}
      width={"100%"}
      height={"100%"}
      playsinline
      config={{
        attributes: {
          autoPlay: true,
          style: { objectFit: "cover", width: "100%", height: "100%" },
        },
      }}
      autoPlay={true}
      style={{ display: "flex", position: "absolute", inset: 0 }}
    />
  ) : (
    <Image
      src={image.imageUrl}
      alt={image.alt || `story_${index}`}
      fill
      style={{ objectFit: "cover" }}
    />
  )
}

export default HeroMedia
