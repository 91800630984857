import { CarModel } from "@/api/generated/BMWInterfaces"

export function formatLabel(value: number): string {
  if (value >= 1000000) {
    return `${(value / 1000000).toLocaleString()} מיליון`
  } else if (value >= 1000) {
    return `${(value / 1000).toFixed(0)} אלף`
  }
  return `${value}₪`
}

export function formatLabelString(value: number): string {
  if (value >= 1000) {
    return `${(value / 1000).toLocaleString(undefined, { maximumFractionDigits: 0 })} אלף`
  }
  return `${value.toLocaleString()}`
}

export function getPriceRange(cars: CarModel[]): {
  minPrice: number
  maxPrice: number
  midPrice: number
} {
  if (cars.length === 0) {
    return { minPrice: 0, maxPrice: 0, midPrice: 0 }
  }

  const prices = cars.map((car) => car.price)
  const minPrice = Math.min(...prices)
  const maxPrice = Math.max(...prices)

  return {
    minPrice,
    maxPrice,
    midPrice: (minPrice + maxPrice) / 2,
  }
}
