import { middleFindCarClick } from "@/lib/gtm"
import styled from "@emotion/styled"

import { Button } from "@mui/material"
import Link from "next/link"

const StyledLayout = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  padding: 16px;
  z-index: 10;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.26);
  ${(props) => props.theme.breakpoints.up("md")} {
    height: fit-content;
    border-radius: 8px;
    width: 196px;
    padding: 8px;
    left: 40px;
    bottom: 70px;
  }
  ${(props) => props.theme.breakpoints.up("xl")} {
    left: 115px;
  }
`

export default function StickyButton(): React.ReactElement {
  return (
    <StyledLayout>
      <Button
        onClick={() => {
          middleFindCarClick()
        }}
        variant="contained"
        LinkComponent={Link}
        href={"/search"}
        fullWidth
      >
        מצא לי רכב
      </Button>
    </StyledLayout>
  )
}
