import { Desktop, Mobile } from "@/lib/common/MediaQueries"
import colors from "@/lib/theme/colors"
import styled from "@emotion/styled"
import { Typography } from "@mui/material"
import Image from "next/image"

const StyledLayout = styled.div`
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  background-color: ${colors.directDisable};
  ${(props) => props.theme.breakpoints.up("md")} {
    gap: 40px;
    padding: 128px 40px;
  }
`

const AdvantagesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  ${(props) => props.theme.breakpoints.up("md")} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 24px;
  }
`
const StyledAdvantage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  padding: 16px;
  text-align: center;
  background-color: white;
  border-radius: 8px;
  border: 1px solid ${colors.directVeryLightGray};
`

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
`

const advantages: { image: string; title: string; descr: string }[] = [
  {
    image: "/assets/fastoffer.svg",
    title: "הצעה מהירה",
    descr: "שריין הצעה במקדמה קטנה כדי להבטיח שהרכב יהיה שלך",
  },
  {
    image: "/assets/driving.svg",
    title: "בחירת רכב",
    descr: "להבטיח לך איכות וחוויית נהיגהיוצאת דופן",
  },
  {
    image: "/assets/profile-circle.svg",
    title: "ייעוץ סוכן חכם",
    descr: "לרשותך כדי לסייע בבחירת הרכב המושלם לצרכיך",
  },
  {
    image: "/assets/star.svg",
    title: "מוכן לצאת לדרך",
    descr: "השלמת העסקה בצורה חלקה ומהירה לרכב הפרימיום שלך",
  },
]

export default function Advantages(): React.ReactElement {
  const title = "יתרונות מבית היבואן"
  return (
    <StyledLayout>
      <Mobile>
        <Typography variant="h3">{title}</Typography>
      </Mobile>
      <Desktop centerHorizontally>
        <Typography variant="h2">{title}</Typography>
      </Desktop>
      <AdvantagesGrid>
        {advantages.slice(0, 4).map((el, i) => (
          <StyledAdvantage key={i}>
            <Image src={el.image} alt={""} width={40} height={40} />
            <TextBox>
              <Typography variant="subtitle1">{el.title}</Typography>
              <Mobile>
                <Typography variant="body2">{el.descr}</Typography>
              </Mobile>
              <Desktop>
                <Typography variant="body1">{el.descr}</Typography>
              </Desktop>
            </TextBox>
          </StyledAdvantage>
        ))}
      </AdvantagesGrid>
    </StyledLayout>
  )
}
