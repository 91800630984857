import { MainBannerMedia } from "@/api/generated/BMWInterfaces"
import { Desktop, Mobile } from "@/lib/common/MediaQueries"
import { lowerVideoClick } from "@/lib/gtm"
import styled from "@emotion/styled"
import { IconButton, Modal, Typography } from "@mui/material"
import Image from "next/image"
import { useState } from "react"
import ReactPlayer from "react-player"
import XIcon from "../shared/icons/XIcon"

const StyledLayout = styled.div`
  width: 100%;
  height: 500px;
  position: relative;
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.8) 100%);
  z-index: 1;
`

const Content = styled.div`
  position: relative;
  z-index: 2;
  color: white;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 56px 16px;
  align-items: center;
  justify-content: center;
  gap: 16px;
`

const StyledButton = styled(IconButton)`
  min-width: 0;
  min-height: 0;
  padding: 0;
`

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: center;
  align-items: center;
`

const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledInnerModal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 500px;
  height: 400px;
`
const StyledIconButton = styled(IconButton)`
  width: fit-content;
`
interface PlayerProps {
  open: boolean
  handleClose: () => void
  videoLink: string
}

function PlayerModal({ open, handleClose, videoLink }: PlayerProps): React.ReactElement {
  return (
    <StyledModal open={open} onClose={handleClose}>
      <StyledInnerModal>
        <StyledIconButton onClick={handleClose}>
          <XIcon />
        </StyledIconButton>
        <ReactPlayer url={videoLink} width={"100%"} height={"100%"} />
      </StyledInnerModal>
    </StyledModal>
  )
}

export default function HeroDrop({ data }: { data: MainBannerMedia }): React.ReactElement {
  const [showPlayer, setShowPlayer] = useState(false)
  const { coverPhoto, videoExplanationTitle, videoLink, videoTitle } = data
  const { imageUrl, alt } = coverPhoto.image
  return (
    <StyledLayout>
      <>
        <Image src={imageUrl} alt={alt || ""} fill style={{ objectFit: "cover" }} />
        <Overlay />
        <Content>
          <StyledButton
            onClick={() => {
              setShowPlayer(true)
              lowerVideoClick()
            }}
          >
            <Image src="/assets/playbutton.svg" alt="" width={40} height={40} />
          </StyledButton>
          <Mobile>
            <TextBox>
              <Typography variant={"h3"} color={"white"}>
                {videoTitle}
              </Typography>
              <Typography variant="body1" color={"white"}>
                {videoExplanationTitle}
              </Typography>
            </TextBox>
          </Mobile>
          <Desktop>
            <TextBox>
              <Typography variant={"h2"} color={"white"}>
                {videoTitle}
              </Typography>
              <Typography variant="h3" color={"white"}>
                {videoExplanationTitle}
              </Typography>
            </TextBox>
          </Desktop>
        </Content>
      </>
      <PlayerModal
        open={showPlayer}
        handleClose={() => setShowPlayer(false)}
        videoLink={videoLink}
      />
    </StyledLayout>
  )
}
