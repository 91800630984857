import { CarModel } from "@/api/generated/BMWInterfaces"
import { Desktop, Mobile } from "@/lib/common/MediaQueries"
import { modelClick, showMoreCarsClick } from "@/lib/gtm"
import styled from "@emotion/styled"
import { Button, Typography } from "@mui/material"
import { useState } from "react"
import CarsCard from "../shared/CarsCard"

const StyledLayout = styled.div`
  display: flex;
  padding: 0 16px;
  flex-direction: column;
  width: 100%;
  gap: 32px;
  ${(props) => props.theme.breakpoints.up("md")} {
    align-items: center;
    gap: 40px;
  }
`

const CarsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;
  ${(props) => props.theme.breakpoints.up("md")} {
    grid-template-columns: repeat(3, 1fr);
    padding: 0 76px;
    max-width: 1200px;
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    grid-template-columns: repeat(3, 1fr);
    padding: 0 106px;
    max-width: 1280px;
  }
`

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) => props.theme.breakpoints.up("md")} {
    align-items: center;
  }
`

const ButtonBox = styled.div`
  display: flex;
  justify-content: center;
`

const StyledButton = styled(Button)`
  color: black;
  text-decoration: underline;
  :hover {
    background-color: transparent;
  }
`

export default function PremiumDeals({ data }: { data: CarModel[] }): React.ReactElement {
  const [showAll, setShowAll] = useState(false)
  const allowedCars = data
    .filter((el) => {
      return el.isActive && new Date(el.dueDate) > new Date()
    })
    .slice(0, 15)
  const displayedCars = showAll ? allowedCars : allowedCars.slice(0, 6)
  const handleShowAll = (): void => setShowAll(true)
  return (
    <StyledLayout>
      <TextBox>
        <Mobile>
          <Typography variant="h3">הזדמנות שלא תחזור!</Typography>
        </Mobile>
        <Desktop centerHorizontally>
          <Typography variant="h2">הזדמנות שלא תחזור!</Typography>
        </Desktop>
        <Mobile>
          <Typography variant="body1">הדילים הכי שווים לרכבי פרימיום</Typography>
        </Mobile>
        <Desktop centerHorizontally>
          <Typography variant="h3">הדילים הכי שווים לרכבי פרימיום</Typography>
        </Desktop>
      </TextBox>
      <CarsGrid>
        {displayedCars.map((el, i) => (
          <CarsCard
            data={el}
            key={i}
            onClick={() => {
              modelClick(el.name)
            }}
          />
        ))}
      </CarsGrid>

      {!showAll && allowedCars.length > 6 ? (
        <ButtonBox>
          <StyledButton
            variant="text"
            onClick={() => {
              showMoreCarsClick()
              handleShowAll()
            }}
            disableRipple
          >
            הצג לי עוד רכבים
          </StyledButton>
        </ButtonBox>
      ) : null}
    </StyledLayout>
  )
}
