"use client"

import { Brand, HomePage } from "@/api/generated/BMWInterfaces"
import { getPriceRange } from "@/lib/common/utils"
import { pageview } from "@/lib/gtm"
import styled from "@emotion/styled"
import { useCallback, useEffect, useRef, useState } from "react"
import Advantages from "./Advantages"
import CarInterest from "./CarInterest"
import FAQComp from "./FAQComp"
import Hero from "./Hero/Hero"
import HeroDrop from "./HeroDrop"
import MoreOffers from "./MoreOffers"
import PremiumDeals from "./PremiumDeals"
import StickyButton from "./StickyButton"

const OutLayout = styled.div`
  display: flex;
  flex-direction: column;
`

const Layout = styled.section`
  padding-bottom: 32px;
  padding-top: 32px;
  gap: 32px;
  align-items: center;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-bottom: 64px;
    padding-top: 64px;
    gap: 64px;
  }
  display: flex;
  flex-direction: column;
`

const InnerLayout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
type Props = {
  data: HomePage
  brands: Brand[]
}

export default function Homepage({ data, brands }: Props): React.ReactElement {
  const [showSticky, setShowSticky] = useState(false)
  const heroRef = useRef<HTMLDivElement>(null)
  const afterHeroRef = useRef<HTMLDivElement>(null)
  const { faq, mainBanner, topModels } = data
  const { minPrice, maxPrice, midPrice } = getPriceRange(topModels)
  const handleStickyButtonClick = useCallback(() => {
    afterHeroRef.current?.scrollIntoView({ behavior: "smooth" })
  }, [])
  useEffect(() => {
    const handleScroll = (): void => {
      if (heroRef.current) {
        const heroBottom = heroRef.current.getBoundingClientRect().bottom

        setShowSticky(heroBottom <= 350)
      }
    }

    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])
  useEffect(() => {
    pageview()
  }, [])
  return (
    <OutLayout>
      <section ref={heroRef}>
        <Hero
          handleStickyButtonClick={handleStickyButtonClick}
          data={data.mainBanner.heros.mainBannerHero}
        />
      </section>
      {showSticky && <StickyButton />}
      <Layout ref={afterHeroRef}>
        <PremiumDeals data={topModels} />
        <InnerLayout>
          <CarInterest data={brands} minPrice={minPrice} maxPrice={maxPrice} midPrice={midPrice} />
          <MoreOffers />
          <Advantages />
          <HeroDrop data={mainBanner.media} />
        </InnerLayout>

        <FAQComp data={faq} />
      </Layout>
    </OutLayout>
  )
}
