import { MainBannerHero } from "@/api/generated/BMWInterfaces"
import colors from "@/lib/theme/colors"
import styled from "@emotion/styled"
import { IconButton } from "@mui/material"
import Image from "next/image"

const StyledControls = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  ${(props) => props.theme.breakpoints.down("md")} {
    width: 100%;
  }
`

const TrackBarContainer = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  padding: 0 16px;
  z-index: 3;
  align-items: center;
`

const Track = styled.div`
  height: 3px;
  flex-grow: 1;
  background-color: ${colors.directVeryLightGray};
  position: relative;
  overflow: hidden;
  width: 100%;
  ${(props) => props.theme.breakpoints.up("md")} {
    width: 64px;
  }
`

const TrackFill = styled.div<{ isActive: boolean; progress: number }>`
  position: absolute;
  right: 0;
  left: 0;
  height: 100%;
  width: ${(props) => `${props.progress}%`};
  background-color: ${colors.directAzure};
`

const StyeldIconButton = styled(IconButton)`
  min-width: 0;
  padding: 0;
  z-index: 10000;
`
type Props = {
  stories: MainBannerHero[]
  currentIndex: number
  progress: number
  isPaused: boolean
  handleResume: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  handlePause: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export default function HeroControls({
  stories,
  currentIndex,
  progress,
  isPaused,
  handlePause,
  handleResume,
}: Props): React.ReactElement {
  return (
    <StyledControls>
      <TrackBarContainer>
        {stories.map((_, index) => (
          <Track key={index}>
            <TrackFill
              isActive={index === currentIndex}
              progress={index === currentIndex ? progress : index < currentIndex ? 100 : 0}
            />
          </Track>
        ))}
      </TrackBarContainer>
      {isPaused ? (
        <StyeldIconButton onClick={(e) => handleResume(e)}>
          <Image src={"/assets/playbutton.svg"} width={24} height={24} alt="play" />
        </StyeldIconButton>
      ) : (
        <StyeldIconButton onClick={(e) => handlePause(e)}>
          <Image src={"/assets/pausebutton.svg"} width={24} height={24} alt="pause" />
        </StyeldIconButton>
      )}
    </StyledControls>
  )
}
