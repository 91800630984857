import styled from "@emotion/styled"

import { MainBannerHero } from "@/api/generated/BMWInterfaces"
import { useEffect, useRef, useState } from "react"
import "stories-react/dist/index.css"
import HeroContent from "./HeroContent"
import HeroControls from "./HeroControls"
import HeroMedia from "./HeroMedia"

const StyledLayout = styled.div`
  width: 100%;
  height: 610px;
  position: relative;
  ${(props) => props.theme.breakpoints.up("md")} {
    height: 740px;
  }
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.06) 0%, rgba(0, 0, 0, 0.48) 100%);
  z-index: 1;
`

const StyledInner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: 32px 16px 80px 16px;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  z-index: 2;
  position: relative;
  ${(props) => props.theme.breakpoints.up("md")} {
    flex-direction: column-reverse;
    gap: 64px;
  }
`

export type Story = {
  title: string
  subTitle: string
  logo: boolean
  url: string
}

// const stories: Story[] = [
//   {
//     url: "https://images.pexels.com/photos/3030268/pexels-photo-3030268.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
//     title: "TITLE1",
//     subTitle: "SUBTITLE1",
//     logo: true,
//   },
//   {
//     url: "https://images.pexels.com/photos/866398/pexels-photo-866398.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
//     title: "TITLE2",
//     subTitle: "SUBTITLE2",
//     logo: true,
//   },
//   {
//     url: "https://images.pexels.com/photos/931881/pexels-photo-931881.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
//     title: "TITLE3",
//     subTitle: "SUBTITLE3",
//     logo: false,
//   },
// ]

const duration = 5000

type Props = {
  handleStickyButtonClick: () => void
  data: MainBannerHero[]
}

export default function Hero({ handleStickyButtonClick, data }: Props): React.ReactElement {
  const stories = data
  const [isPaused, setIsPaused] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [progress, setProgress] = useState(0)
  const intervalRef = useRef<NodeJS.Timeout | null>(null)
  const progressIntervalRef = useRef<NodeJS.Timeout | null>(null)
  const pausedTimeRef = useRef<number | null>(null)
  const currentStory = stories[currentIndex]

  const goToNext = (): void => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % stories.length)
    setProgress(0)
    pausedTimeRef.current = null
  }

  useEffect(() => {
    if (!isPaused) {
      if (intervalRef.current) clearInterval(intervalRef.current)
      if (progressIntervalRef.current) clearInterval(progressIntervalRef.current)

      const startTime = Date.now() - (pausedTimeRef.current || 0)

      progressIntervalRef.current = setInterval(() => {
        const elapsedTime = Date.now() - startTime
        const newProgress = Math.min((elapsedTime / duration) * 100, 100)
        setProgress(newProgress)

        if (newProgress >= 100) {
          goToNext()
        }
      }, 10)
    } else {
      if (progressIntervalRef.current) clearInterval(progressIntervalRef.current)
      pausedTimeRef.current = (progress / 100) * duration
    }

    return () => {
      // if (intervalRef.current) clearInterval(intervalRef.current)
      if (progressIntervalRef.current) clearInterval(progressIntervalRef.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPaused, currentIndex])

  const handlePause = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.stopPropagation()
    setIsPaused(true)
  }

  const handleResume = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.stopPropagation()
    setIsPaused(false)
  }

  return (
    <StyledLayout onClick={goToNext}>
      <HeroMedia image={currentStory.image.image} index={currentIndex} />
      <Overlay />
      <StyledInner>
        <HeroControls
          stories={stories}
          currentIndex={currentIndex}
          handlePause={handlePause}
          handleResume={handleResume}
          isPaused={isPaused}
          progress={progress}
        />
        <HeroContent story={currentStory} handleStickyButtonClick={handleStickyButtonClick} />
      </StyledInner>
    </StyledLayout>
  )
}
